import type { Nullable } from '@models/CustomUtilityTypes';
import type { WhitelabelAppConfig } from '@models/WhitelabelAppConfig';
import type { RouterConfig } from '@nuxt/schema';
import type {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from 'vue-router';

type ScrollPosition = Nullable<{
  behavior?: ScrollOptions['behavior'];
  left: number;
  top: number;
}>;

export default {
  scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    savedPosition: ScrollPosition
  ) {
    if (isEmpty(from.meta)) {
      // do not do any scrolling, if we are coming from a redirect
      return undefined;
    }

    const appConfig: WhitelabelAppConfig = useWhlAppConfig();
    const scrollStore = useScrollStore();

    // 1. Check for our custom saved position
    const customSavedPosition = scrollStore.getPosition(
      to.name as string,
      to.params as Record<string, string>
    );

    if (customSavedPosition) {
      return {
        el: appConfig.shadowRoot?.host,
        behavior: 'smooth',
        top: customSavedPosition.y,
      };
    }

    // 2. Use browser's native savedPosition if available (back/forward navigation)
    if (savedPosition) {
      return savedPosition;
    }

    // 3. Default behavior - scroll to top
    return {
      el: appConfig.shadowRoot?.host,
      behavior: 'smooth',
      top: 0,
    };
  },
} satisfies RouterConfig;
