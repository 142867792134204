// @ts-expect-error virtual file
import plugins from '#build/plugins';
// @ts-expect-error virtual file
import RootComponent from '#build/root-component.mjs';

import {
  applyPlugins,
  createError,
  createNuxtApp,
  type NuxtApp,
  type NuxtError,
} from 'nuxt/app';

import '#build/css';
import { createApp, nextTick } from 'vue';

// These files must be imported first as they have side effects:
// 1. (we set __webpack_public_path via this import, if using webpack builder)
import '#build/paths.mjs';
// 2. we set globalThis.$fetch via this import
import '#build/fetch.mjs';

declare global {
  interface Window {
    __IMXPLATFORM__WIDGET__: {
      widgetBaseUrl: string;
      widgetCurrentHost: string;
      widgetStyleUrl: string;
    };
  }
}

// declare global variables
window.__IMXPLATFORM__WIDGET__ = {
  // will be rewritten by server-side includes (nginx knows about correct url through environment variables; see default.conf.template)
  widgetBaseUrl: import.meta.env.DEV
    ? 'https://whitelabel.infomax.dev/'
    : 'https://<!--#echo var="host" default="" -->/',
  widgetCurrentHost: import.meta.env.DEV
    ? 'whitelabel.infomax.dev'
    : '<!--#echo var="host" default="unknown" -->',
  widgetStyleUrl: import.meta.env.DEV
    ? 'https://widget-whitelabel.infomax.dev/_nuxt/style.css'
    : 'https://<!--#echo var="host" default="" -->/widget/style.css',
};

let entryPromise: Promise<void>;

const ATTRIBUTE_LANGUAGE = 'language';
const ATTRIBUTE_WIDGET_ID = 'widget-id';

let entry = (): Promise<void> => Promise.resolve();

if (import.meta.client) {
  class VueWrapper extends HTMLElement {
    static observedAttributes = [ATTRIBUTE_LANGUAGE, ATTRIBUTE_WIDGET_ID];

    private nuxtApp!: NuxtApp;

    constructor() {
      super();
      this.attachShadow({ mode: 'open' });

      // dev-server: inject single styles into shadow dom
      if (import.meta.env.DEV) {
        document.head.appendChild = (elem) => {
          this.shadowRoot?.appendChild(elem);
          return elem;
        };

        // Apply widget-id attribute to shadow root
        this.shadowRoot?.host.setAttribute(ATTRIBUTE_WIDGET_ID, '1');
      }
    }

    async connectedCallback() {
      // @ts-expect-error: Object is possibly 'null'.
      this.shadowRoot.innerHTML = '<div id="app"></div>';
      console.debug('IMXWHL Inserted #app element into shadowRoot');

      // production-build: inject styles into shadow dom
      if (!import.meta.env.DEV) {
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.href = window.__IMXPLATFORM__WIDGET__.widgetStyleUrl;
        this.shadowRoot?.prepend(style);
        console.debug('IMXWHL prepended style element to shadowRoot');
      }

      const vueApp = createApp(RootComponent);
      console.debug(
        'IMXWHL created imx.Platform Whitelabel widgets client-side vue app'
      );

      const globalName = 'imxplatform' + Math.round(Math.random() * 10000);
      const nuxt = createNuxtApp({
        vueApp,
        globalName,
      });
      console.debug(
        'IMXWHL created imx.Platform Whitelabel widgets client-side nuxt app, with globalName: ' +
          globalName
      );

      async function handleVueError(error: unknown) {
        await nuxt.callHook('app:error', error);
        nuxt.payload.error =
          nuxt.payload.error ||
          createError(error as string | Partial<NuxtError<unknown>>);
      }

      vueApp.config.errorHandler = handleVueError;
      console.debug('IMXWHL registered error handler within vue app');

      try {
        await applyPlugins(nuxt, plugins);
        console.debug('IMXWHL applied plugins');
      } catch (err) {
        console.debug('IMXWHL error while applying plugins');
        handleVueError(err);
      }

      try {
        await nuxt.hooks.callHook('app:created', vueApp);
        console.debug('IMXWHL called app:created hook');

        // pass initial app configuration to nuxt app
        nuxt._appConfig.shadowRoot = this.shadowRoot;
        nuxt._appConfig.defaultLanguage =
          this.getAttribute(ATTRIBUTE_LANGUAGE) || undefined;
        nuxt._appConfig.widgetId = isConvertibleToNumber(
          this.getAttribute(ATTRIBUTE_WIDGET_ID)
        )
          ? Number(this.getAttribute(ATTRIBUTE_WIDGET_ID))
          : undefined;

        await nuxt.hooks.callHook('app:beforeMount', vueApp);
        console.debug('IMXWHL called app:beforeMount hook');

        const appElement = this.shadowRoot?.querySelector('#app');

        if (appElement) {
          vueApp.mount(appElement);
          await nuxt.hooks.callHook('app:mounted', vueApp);
          console.debug('IMXWHL called app:mounted hook');
          await nextTick();
          console.debug('IMXWHL nextTick()');

          this.nuxtApp = nuxt;
        } else {
          console.error('IMXWHL Could not find root app element "#app"');
        }
      } catch (err) {
        console.debug('IMXWHL error while mounting app', err);
        handleVueError(err);
      }

      // if the errorHandler is not overridden by the user, we unset it
      if (vueApp.config.errorHandler === handleVueError) {
        vueApp.config.errorHandler = undefined;
      }
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
      if (this.nuxtApp) {
        // make nuxt app reactive to changes from outside
        switch (name) {
          case ATTRIBUTE_LANGUAGE:
            this.nuxtApp._appConfig.defaultLanguage = newValue;
            break;
          case ATTRIBUTE_WIDGET_ID:
            this.nuxtApp._appConfig.widgetId = isConvertibleToNumber(newValue)
              ? Number(newValue)
              : undefined;
        }
      }
    }
  }

  // class EventListElement extends VueWrapper {
  // }

  entry = async function initApp(): Promise<void> {
    if (entryPromise) {
      return entryPromise;
    }

    // register custom element
    customElements.define('imxplatform-whitelabel-widget', VueWrapper);
  };

  entryPromise = entry().catch((error: unknown) => {
    console.error('IMXWHL Error while registering custom elements:', error);
    throw error;
  });
}
export default () => entry();
