<script setup lang="ts">
import { WhlConsentManagerType } from '../../models/WhlConsentManagerType';
import {
  ConsentManagerSymbol,
  createConsentManager,
} from '../../utils/consent-manager';
import { DEFAULT_LANGUAGE } from './model/constants';

// configure i18n
const config = useWhlAppConfig();
const { setLocale } = useI18n({
  locale: config.defaultLanguage ?? DEFAULT_LANGUAGE,
});

// set local based on passed attribute from outside
watch(
  () => config.defaultLanguage,
  (value?: string) => {
    setLocale(value ?? DEFAULT_LANGUAGE);
  },
  { immediate: true }
);

// initialize consent management
const consentManager = createConsentManager(WhlConsentManagerType.NULL);
provide(ConsentManagerSymbol, consentManager);

if (import.meta.client) {
  await consentManager.init();
}
</script>

<template>
  <NuxtLoadingIndicator :color="false" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="css">
/* beware: transition styles must be global and do not work, if they are scoped */
.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
