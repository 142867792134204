import { BaseLocationType } from '../models/BaseLocation';
import { MediaTypeEnum } from '../gql/schema';
import {
  type BaseInstanceConfig,
  ContactType,
} from '../models/BaseInstanceConfig';
import { BaseTourDifficulty } from '../models/BaseTourDifficulty';

enum HHT_TOUR_CATEGORIES {
  FAHRRADROUTE = 1,
  MARITIME_ROUTE = 3,
  SPAZIERGANG = 2,
}

export const CONFIG: Omit<BaseInstanceConfig, 'instanceId'> = {
  defaultWidgetId: 1,
  defaults: {
    hero: {
      filter: { mediaType: [MediaTypeEnum.Image], imageType: { eq: 4 } },
      fallback: [{ mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } }],
    },
    gallery: {
      filter: {
        or: [
          { mediaType: [MediaTypeEnum.Image], imageType: { eq: 1 } },
          { mediaType: [MediaTypeEnum.Video] },
        ],
      },
    },
    list: {
      filter: {
        mediaType: [MediaTypeEnum.Image],
        imageType: { oneOf: [4, 1] },
      },
    },
    article: {
      contact: ContactType.CONTACT,
    },
  },
  enabledLanguages: ['de', 'en'],
  event: {
    list: {
      list: {
        standard: {
          showCategory: true,
          showContactAddress: true,
          categoryBlacklistRegex: RegExp('(Sonstige|^Weitere.*)'),
        },
      },
    },
    overview: {
      slider: {
        teaser: {
          showCategory: true,
          showContactAddress: true,
          categoryBlacklistRegex: RegExp('(Sonstige|^Weitere.*)'),
        },
      },
    },
    detail: {
      displayRelatedRecords: false,
      article: {
        showInfoAttributes: false,
        showContributor: false,
      },
      hero: {
        showCategory: true,
        showContactAddress: true,
        categoryBlacklistRegex: RegExp('(Sonstige|^Weitere.*)'),
      },
    },
  },
  footerContact: {
    name: 'Hamburg und Metropolregion',
    street: 'Wexstraße',
    streetNo: '7',
    zipcode: '20355',
    city: 'Hamburg',
    email: 'info@hamburg-tourismus.de',
  },
  footerLogo: '/assets/images/instances/hht/logo.white.svg',
  graphqlBearerToken:
    'eyJraWQiOiJpbXgtY2RhIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczpcL1wvaGh0LmlteHBsYXRmb3JtLmRlXC9vYXV0aCIsInN1YiI6IjQzMjciLCJsbmFtZSI6IndzLndoaXRlbGFiZWwtd2lkZ2V0cyJ9.dacFl2nn6BpXtidVEPVSvukJxacpAX36yZrPQ7baVrlMZVUkPCnS-6dxuNOCff7oNcemt84PDgkhxKo2V3vOfJDSuMesaAk_Jk20V80yvba2J3DvmRfR2i5sO0YiaXCsWg08hUpcrnHRO_99X1oZvIQhYllNdPAryxsESvqXp9d3WJJsiIw96Td5njU2x4KG0KcLOl_0Ii0THBTQk1FwA2sEWvEjmXGAM18njkZ_teY0mTc8t-umcRr2z8_U-MKrNHMPabrhaXjAxoIh7Hdv9Btz-TsNJJWMrsODV7fmnRCpngG1ptNtZ3-3GSRFSixdU-FQfjOvmftezLZLWZa97A',
  graphqlEndpoint: `https://content-delivery.imxplatform.de/hht/imxplatform`,
  headerLogo: '/assets/images/instances/hht/logo.colored.svg',
  map: {
    defaultCoords: {
      // hamburg
      latitude: 53.5504344,
      longitude: 9.9897586,
    },
    maptoolkitApiKey: 'hht',
    smartScroll: true,
  },
  pageheader: {
    search: {
      locationSearchWhitelist: [
        BaseLocationType.ADDRESS_POI_GROUP,
        BaseLocationType.LOCATION,
      ],
    },
  },
  poi: {
    detail: {
      displayRelatedRecords: false,
    },
    openingHours: {
      hideDateRangeInfo: true,
    },
  },
  tour: {
    detail: {
      displayRelatedRecords: false,
    },
    categoryIcons: {
      [HHT_TOUR_CATEGORIES.FAHRRADROUTE]: 'ion:bicycle',
      [HHT_TOUR_CATEGORIES.SPAZIERGANG]: 'ion:active',
      [HHT_TOUR_CATEGORIES.MARITIME_ROUTE]: 'ion:boat-outline',
    },
    difficultyMapping: {
      1: BaseTourDifficulty.EASY,
      2: BaseTourDifficulty.EASY,
      3: BaseTourDifficulty.MEDIUM,
      4: BaseTourDifficulty.HARD,
      5: BaseTourDifficulty.HARD,
    },
  },
};
